<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <a :href="cardContent.Link" target="_blank" class="info-card-link">
      <v-img
        v-if="cardContent.Image"
        :src="cardContent.Image"
        height="100px"
      ></v-img>
    </a>

    <v-card-title>
      <a :href="cardContent.Link" target="_blank" class="info-card-link">{{cardContent.Title}}</a>
    </v-card-title>

    <v-card-subtitle v-if="cardContent.SubTitle">
      <a :href="cardContent.Link" target="_blank" class="info-card-link">{{cardContent.SubTitle}}</a>
    </v-card-subtitle>

    <v-card-actions v-if="cardContent.Description">
      <v-btn
        color="orange lighten-2"
        text
      >
        <a :href="cardContent.Link" target="_blank" class="info-card-link">See More</a>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition v-if="cardContent.Description">
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          <a :href="cardContent.Link" target="_blank" class="info-card-link">{{cardContent.Description}}</a>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    cardContent: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    show: false,
  }),
}
</script>