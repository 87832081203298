<template>
  <v-dialog
    v-model="getProjectSettingsDisplayValue"
    persistent
    max-width="30rem"
    :key="getProjectSettingsDisplayValue"
  >
    <v-card>
      <div class="button-close-large-container">
        <v-btn v-show="getProjectSettingsDisplayValue" @click="closeEditor" fab color="primary" class="button-close-large"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-card-title>
        <span class="headline editor-heading-bold underline">Editing {{projectData.ProjectName}}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            col="12"
            sm="12"
            md="12"
          >
            <v-text-field
              label="Project Name:"
              v-model="projectData.ProjectName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            col="12"
            sm="4"
            md="4"
          >
            <v-text-field
              label="Project Version:"
              v-model="projectData.Version"
            ></v-text-field>
          </v-col>
          <v-col
            col="12"
            sm="4"
            md="4"
          >
            <v-select
              label="Symbol Character Length"
              :items="[1, 2, 3, 4]"
              v-model="projectData.SymbolLength"
            ></v-select>
          </v-col>
          <v-col
            col="12"
            sm="4"
            md="4"
          >
            <v-select
              label="Code Character Length"
              :items="[1, 2, 3, 4, 5, 6, 7, 8]"
              v-model="projectData.CodeLength"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            col="12"
            sm="12"
            md="12"
          >
            <v-text-field
              label="Project Author:"
              v-model="projectData.Author"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            col="12"
            sm="12"
            md="12"
          >
            <v-text-field
              label="Author Email:"
              v-model="projectData.Email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            col="12"
            sm="12"
            md="12"
          >
            <v-textarea
              label="Project Description:"
              v-model="projectData.Description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            col="12"
            sm="12"
            md="12"
          >
            <v-text-field
              :disabled="true"
              label="Project GUID:"
              v-model="projectData.ProjectId"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="saveProjectSettings"
          >
            Save
        </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="closeEditor"
          >
            Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createGUID } from '@/helpers'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ProjectSettingsEditor',
  props: {
    projectSettings: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    projectData: {
      ProjectName: '',
      Version: '',
      Author: '',
      Email: '',
      Description: '',
      ProjectId: '',
      SymbolLength: 3,
      CodeLength: 7
    }
  }),
  computed: {
    ...mapGetters([
      'getProjectSettingsDisplayValue',
      'getProjectSettings'
    ]),
    getProjectDataContent() {
      return this.projectSettings
    }
  },
  methods: {
    ...mapActions([
      'switchProjectSettingsDisplayValue',
      'updateProjectSettingsData'
    ]),
    closeEditor() {
      this.switchProjectSettingsDisplayValue()
    },
    saveProjectSettings() {
      this.updateProjectSettingsData(this.projectData)
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Updated Project Details', {
            email: this.projectData.Email,
            name: this.projectData.Author,
            projectId: this.projectData.ProjectId,
            projectName: this.projectData.ProjectName,
            projecVersion: this.projectData.Version
        })
        if (this.getProjectSettings.UserId) {
          window.analytics.identify(this.getProjectSettings.UserId, {
            email: this.projectData.Email,
            name: this.projectData.Author,
            project: this.projectData.ProjectId
          })
        } else {
          window.analytics.identify({
            name: this.projectData.Author,
            projectId: this.projectData.ProjectId,
            email: this.projectData.Email
          })
        }
      }
      this.closeEditor()
    }
  },
  watch: {
    getProjectSettingsDisplayValue(newValue) {
      if (newValue) {
        const data = JSON.parse(JSON.stringify(this.projectSettings))
        this.projectData.ProjectName = data.ProjectName ? data.ProjectName : ''
        this.projectData.Version = data.Version ? data.Version : 'v0.0.1'
        this.projectData.Author = data.Author ? data.Author : ''
        this.projectData.Email = data.Email ? data.Email : ''
        this.projectData.Description = data.Description ? data.Description : ''
        this.projectData.ProjectId = data.ProjectId ? data.ProjectId : createGUID()
        this.projectData.SymbolLength = data.SymbolLength ? data.SymbolLength : 3
        this.projectData.CodeLength = data.CodeLength ? data.CodeLength : 7
      }
    }
  }
}
</script>