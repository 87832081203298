import {
    UPDATED_APPLICATION_VERSION
} from '@/store/mutation-types'
import { version } from '@/../package.json'

const state = {
    appVersion: '0.0.0'
}

const getters = {
    getAppVersion() { return state.appVersion }
}

const actions = {
    setAppVersion({commit}) {
        commit(UPDATED_APPLICATION_VERSION, version)
    }
}

const mutations = {
    [UPDATED_APPLICATION_VERSION](state, version) {
        state.appVersion = version
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}