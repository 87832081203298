<template>
  <div width="100%" id="table" v-click-outside="deselectCurrentActiveCard" @mouseleave="deselectCurrentActiveCard">
    <div class="spacer1"></div>
    <div class="spacer2"></div>
    <div class="spacer3"></div>
    <div class="spacer4"></div>
    <div class="spacer5"></div>
    <div class="info-box">
      <InfoHeader v-if="currentActiveCard" :card="currentActiveCard" :editMode="editMode" :swapMode="swapMode" :duplicateMode="duplicateMode" />
      <CategoryPanel />
    </div>
    <div
      v-for="(card, i) in getCardsData"
      :key="i"
      class="elementWrapper"
      @mouseenter="selectCurrentActiveCard(card)"
      @click="openDialogEdit(card)"
    >
      <div class="routerWrap">
        <table-card 
          :card="card" 
          :id="i" 
          :editMode="editMode"
          :cardsToSwap="cardsToSwap"
          :swapMode="swapMode"
          :cardsToDuplicate="cardsToDuplicate"
          :duplicateMode="duplicateMode"
          :activeToggleMode="activeToggleMode"
        />
      </div>
    </div>
    <EditModal :card="currentCard" :dialog="dialog" />
    <ContentModal :contentDialog="contentDialog" :card="currentCard" />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import { mapActions, mapGetters } from 'vuex'
import ContentModal from '@/components/modals/content-modal.vue'
import EditModal from '@/components/modals/edit-modal.vue'
import CategoryPanel from '@/components/info-panel/category-panel.vue'
import InfoHeader from '@/components/info-panel/info-header.vue'
import TableCard from '@/components/table/table-card.vue'

export default {
  name: 'Table',
  props: {
    editMode: {
      type: Boolean,
      default: () => false
    },
    swapMode: {
      type: Boolean,
      default: () => false
    },
    duplicateMode: {
      type: Boolean,
      default: () => false
    },
    clearMode: {
      type: Boolean,
      default: () => false
    },
    activeToggleMode: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    dialog: false,
    contentDialog: false,
    currentCard: {},
    currentActiveCard: {},
    cardsToSwap: [],
    cardsToSwapObjects: [],
    cardsToDuplicate: [],
    cardsToDuplicateObject: []
  }),
  components: {
    TableCard,
    EditModal,
    InfoHeader,
    CategoryPanel,
    ContentModal
  },
  computed: {
    ...mapGetters([
      'getCardsData'
    ])
  },
  methods: {
    ...mapActions([
      'swapCards',
      'duplicateCards'
    ]),
    selectCurrentActiveCard(card) {
      this.currentActiveCard = card
    },
    deselectCurrentActiveCard() {
      this.currentActiveCard = {}
    },
    openDialogEdit(card) {
      this.currentCard = card
      if (this.editMode) {
        this.dialog = true
      } else if(this.swapMode) {
        this.swapCardsMethod(card)
      } else if(this.duplicateMode) {
        this.duplicateCardsMethod(card)
      } else if (this.clearMode) {
        this.clearModeMethod(card)
      } else if (this.activeToggleMode) {
        this.activeToggleMethod(card)
      } else if(card.Active) {
        this.contentDialog = true
      }
    },
    closeContentDialog() {
      this.contentDialog = false
    },
    closeDialog() {
      this.resetDialogData()
    },
    resetDialogData() {
      this.dialog = false
      this.currentCard = {}
    },
    swapCardsMethod(card) {
      if (card.Active) {
        this.cardsToSwap.push(card.PositionId)
        this.cardsToSwapObjects.push(card)
        if (this.cardsToSwapObjects.length === 2) {
          const swapRequest = {
            a: this.cardsToSwapObjects[0],
            b: this.cardsToSwapObjects[1]
          }
          this.swapCards(swapRequest)
          this.cardsToSwap = []
          this.cardsToSwapObjects = []
        }
      }
    },
    duplicateCardsMethod(card) {
      if (card.Active) {
        this.cardsToDuplicate.push(card.PositionId)
        this.cardsToDuplicateObject.push(card)
        if (this.cardsToDuplicateObject.length === 2) {
          const duplicateRequest = {
            a: this.cardsToDuplicateObject[0],
            b: this.cardsToDuplicateObject[1]
          }
          this.duplicateCards(duplicateRequest)
          this.cardsToDuplicate = []
          this.cardsToDuplicateObject = []
        }
      }
    },
    clearModeMethod(card) {
      if (card.Active) {
        card.DisplayId = ""
        card.Symbol = "Dft"
        card.Title = "Default"
        card.Description = ""
        card.Content = ""
        card.Category.Color = "#d3d3d3ff"
        card.Category.Title = "None"
      }
    },
    activeToggleMethod(card) {
      card.Active = !card.Active
    }
  },
  directives: {
    ClickOutside
  },
  watch: {
    swapMode() {
      this.cardsToSwap = []
    },
    duplicateMode() {
      this.cardsToDuplicateObject = []
    }
  }
}
</script>

<style lang="scss" scoped>
.spacer1 {
  grid-area: w1;
}
.spacer2 {
  grid-area: w2;
}
.spacer3 {
  grid-area: w3;
}
.spacer4 {
  grid-area: w4;
}
.spacer5 {
  grid-area: w5
}
.info-box {
  grid-area: wb;
}
#table {
  margin-top: 1vw;
  margin-left: 3vw;
  display: inline-grid;
  grid-template-columns: repeat(18, 1fr);
  grid-template-areas:
      ".  .  wb wb wb wb wb wb wb wb wb wb .  .  .  .  .  ."
      ".  .  wb wb wb wb wb wb wb wb wb wb .  .  .  .  .  ."
      ".  .  wb wb wb wb wb wb wb wb wb wb .  .  .  .  .  ."
      ".  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  ."
      ".  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  ."
      ".  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  ."
      ".  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  ."
      "w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 w4 ."
      "w1 .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  w5 "
      "w2 .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  w3 ";
  .elementWrapper {
      display: flex;
      justify-content: center;
      width: 4.86vw;
      max-height: 115px;
      max-width: 120.95px;
      overflow: hidden;
      .routerWrap {
          object-fit: cover;
          width: 100%;
          height: 100%;
          text-decoration: none;
      }
  }
}
</style>