export const schema = {
  type: "object",
  required: ["ProjectSettings", "Cards"],
  additionalProperties: false,
  properties: {
    ProjectSettings: {
      type: "object",
      required: [],
      additionalProperties: false,
      properties: {
        ProjectName: {
          type: "string",
        },
        Version: {
          type: "string",
        },
        Author: {
          type: "string",
        },
        Email: {
          type: "string",
        },
        Description: {
          type: "string",
        },
        ProjectId: {
          type: "string",
        },
        SymbolLength: {
          type: "number",
        },
        CodeLength: {
          type: "number",
        },
      },
    },
    Cards: {
      type: "array",
      items: {
        type: "object",
        required: [],
        additionalProperties: false,
        properties: {
          PositionId: {
            type: "integer",
          },
          DisplayId: {
            type: "string",
          },
          Active: {
            type: "boolean",
          },
          Symbol: {
            type: "string",
          },
          Title: {
            type: "string",
          },
          Description: {
            type: "string",
          },
          Content: {
            type: "string",
          },
          Category: {
            type: "object",
            required: [],
            additionalProperties: false,
            properties: {
              Color: {
                type: "string",
              },
              Title: {
                type: "string",
              },
            },
          },
          Icon: {
            type: "string",
          },
          Points: {
            type: "array",
            items: {
              type: "object",
              required: [],
              additionalProperties: false,
              properties: {
                Title: {
                  type: "string",
                },
                Image: {
                  type: "string",
                },
                SubTitle: {
                  type: "string",
                },
                Description: {
                  type: "string",
                },
                Link: {
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
};
