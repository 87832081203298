<template>
  <v-btn
    @click="toggleEditModeValue"
  >
    <span class="mr-2">Edit Mode: <span v-if="editMode" class="cbutton-active">ON</span><span v-if="!editMode" class="button-inactive">OFF</span></span>
    <v-icon :color="editMode ? 'green': 'red'">mdi-pencil</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'EditButton',
  props: {
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    toggleEditModeValue() {
      this.$emit('edited', this.editMode)
    }
  }
}
</script>