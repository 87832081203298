<template>
  <div :class="[!card.Active ? 'card-inactive': null, activeToggleMode ? 'active-toggle-mode':null]">
    <div v-if="card.Active && card.Title" :class="['card', editMode ? 'edit-mode': null, isCardSwap ? 'edit-mode': null, isCardDuplicate ? 'edit-mode': null]" :style="`background-color:${card.Category.Color};`">
      <div class="card-id">
        <span v-if="card.DisplayId" style="font-weight:500;">{{card.DisplayId}}</span><span v-else>{{card.PositionId + 1}}</span>
        <span v-if="card.Code" style="float:right;font-weight:400;">{{card.Code}}</span>
        <span v-if="editMode" class="card-categories-edit" :style="`font-size: 7px; margin-left: auto; background-color: ${card.Category.Color}; font-weight: 700;`">{{card.Category.Title.slice(0, 12)}}</span>
      </div>
      <div class="card-symbol">
        {{card.Symbol}}
      </div>
      <p class="title">{{card.Title}}</p>
    </div>
    <div v-else :class="['card', editMode ? 'edit-mode': null]" style="height: 4.86vw;">
      <div class="inactive"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableCard',
  props: {
    card: {
      type: Object,
      default: () => {}
    },
    id: {
      type: Number,
      default: () => 0
    },
    editMode: {
      type: Boolean,
      default: () => false
    },
    cardsToSwap: {
      type: Array,
      default: () => []
    },
    cardsToDuplicate: {
      type: Array,
      default: () => []
    },
    swapMode: {
      type: Boolean,
      default: () => false
    },
    duplicateMode: {
      type: Boolean,
      default: () => false
    },
    activeToggleMode: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    isCardSwap() {
      if (this.cardsToSwap.includes(this.card.PositionId) && this.swapMode) {
        return true
      } else {
        return false
      }
    },
    isCardDuplicate() {
      if (this.cardsToDuplicate.includes(this.card.PositionId) && this.duplicateMode) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-mode {
  border: 1px solid lightsalmon;
  background-color: aqua !important;
}
.card-inactive {
  height: 100%;
}
.card {
  color: rgba(37, 25, 25, 0.55);
  width: 100%;
  height: 100%;
  max-width: 4.86vw;
  // opacity: 0;
  max-height: 4.86vw;
  overflow: hidden;
  text-align: center;
  padding: 0.35vw;
  transition: opacity 0.4s;
  &-id {
    text-align: left;
    font-size: 0.65vw;
    font-weight: 200;
    color: #000000;
  }
  &-symbol {
    font-weight: 600;
    font-size: 1.5vw;
    line-height: 1.5vw;
  }
  .title {
    color: #000000;
    font-weight: 600 !important;
    font-size: 0.70vw !important;
    margin-top: -0.24vw;
    opacity: 0.9;
    font-weight: 200;
    line-height: 1.2;
    margin-top: 2px;
  }
  &:hover {
      color: rgba(255, 255, 255, 0.9);
  }
}
.active-toggle-mode {
  border: .5px solid #000000;
}
</style>