// Application Details
export const UPDATED_APPLICATION_VERSION = 'UPDATED_APPLICATION_VERSION'

// Initial data load
export const INITIAL_DATA_LOAD = 'INITIAL_DATA_LOAD'
export const UPLOAD_DATA_LOAD = 'UPLOAD_DATA_LOAD'
export const UPLOAD_PROJECT_DATA_LOAD = 'UPLOAD_PROJECT_DATA_LOAD'

// Project Details
export const PROJECT_SETTINGS_DISPLAY_UPDATE = 'PROJECT_SETTINGS_DISPLAY_UPDATE'

// Current Cards
export const CURRENT_CARD = 'CURRENT_CARD'

// Categories
export const UPDATED_CARD_CATEGORIES = 'UPDATED_CARD_CATEGORIES'
export const INITIAL_LOAD_ALL_CATEGORIES = 'INITIAL_LOAD_ALL_CATEGORIES'