<template>
  <v-btn
    class="duplicate-button"
    @click="toggleDuplicateModeValue"
  >
    <span class="mr-2">Duplicate Mode: <span v-if="duplicateMode" class="button-active">ON</span><span v-if="!duplicateMode" class="button-inactive">OFF</span></span>
    <v-icon :color="duplicateMode ? 'green': 'red'">mdi-content-duplicate</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'DuplicateButton',
  props: {
    duplicateMode: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    toggleDuplicateModeValue() {
      this.$emit('duplicate', this.duplicateMode)
    }
  }
}
</script>

<style lang="scss" scoped>
.duplicate-button {
  margin-left: 1rem;
}
</style>