<template>
  <div class="header-button-upload-content">
    <v-file-input
      v-model="attachment"
      label="Upload Data File"
      accept="application/json"
      prepend-icon="mdi-paperclip"
      outlined
    >
    </v-file-input>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validateJsonSchema } from "../../helpers/content-editor";
import { schema } from "../../data/validation/jsonSchema";

export default {
  name: "UploadDataButton",
  data: () => ({
    attachment: null,
  }),
  computed: {
    ...mapGetters(["getProjectSettings"]),
  },
  methods: {
    ...mapActions([
      "uploadCardsData",
      "updateProjectSettingsData",
      "createInitialCategoriesArray",
    ]),
  },
  watch: {
    // attachment() {
    //   alert("This feature will be coming soon");
    // },
    attachment(newValue) {
      let results = [];
      if (newValue !== null) {
        let reader = new FileReader();
        reader.readAsText(newValue);
        reader.onload = (e) => {
          try {
            results = JSON.parse(e.target.result);
            if (validateJsonSchema(schema, results)) {
              this.updateProjectSettingsData(results.ProjectSettings);
              this.uploadCardsData(results.Cards);
              this.createInitialCategoriesArray();
              alert("Data Uploaded successfully");
            } else {
              alert("Invalid data uploaded");
            }
          } catch (error) {
            // trigger error
            alert("Invalid file uploaded");
            // clear attachment
            this.attachment = null;
          }
          if (process.env.NODE_ENV === "production") {
            window.analytics.track("Project Loaded", {
              projectId: results.ProjectSettings.ProjectId,
              projectName: results.ProjectSettings.ProjectName,
              projectAuthor: results.ProjectSettings.Author,
              projectEmail: results.ProjectSettings.Email,
              projectVersion: results.ProjectSettings.Version,
            });
            if (this.getProjectSettings.UserId) {
              window.analytics.identify(this.getProjectSettings.UserId, {
                userId: results.ProjectSettings.Email,
                name: results.ProjectSettings.Author,
                projectId: results.ProjectSettings.ProjectId,
              });
            } else {
              window.analytics.identify({
                name: results.ProjectSettings.Author,
                projectId: results.ProjectSettings.ProjectId,
              });
            }
          }
        };
      }
    },
  },
};
</script>
