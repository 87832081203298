import {
  INITIAL_DATA_LOAD,
  INITIAL_LOAD_ALL_CATEGORIES,
  UPLOAD_DATA_LOAD,
  CURRENT_CARD,
  UPDATED_CARD_CATEGORIES,
  UPLOAD_PROJECT_DATA_LOAD
} from '@/store/mutation-types'
import Data from '@/data/card-data/card-data-elements.json'
import {createGUID, LS} from '@/helpers'
import {LOCAL_STORAGE} from '@/config/local-storage.js'
// import axios from '@/utils/axios.js'

const state = {
  projectSettings: {},
  cardsData: [],
  categories: [],
  allCategories: []
}

const getters = {
  getProjectSettings() { return state.projectSettings },
  getCardsData() {
    return state.cardsData
  },
  getCategories() {
    return state.categories
  },
}

const actions = {
  checkIfCategoryColorExists({state}, colorHex) {
    if (state.categories.length > 0) {
      let isExists = false
      state.categories.forEach(category => {
        if (category.Color === colorHex) {
          isExists = true
        }
      })
      return isExists
    }
  },
  // Create array of all categories based on cardsData
  createInitialCategoriesArray({commit, state}) {
    if (state.cardsData.length > 0) {
      let allCategories = []
      state.cardsData.forEach(card => {
        allCategories.push(card.Category)
      });
      commit(INITIAL_LOAD_ALL_CATEGORIES, allCategories)

      // unique object array filter
      // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
      if (state.allCategories.length > 0) {
        const defaultValue = {
          Title: 'None',
          Color: '#d3d3d3ff'
        }
        let uniqueCategories = state.allCategories.filter((value, index, self) => index === self.findIndex((t) => (t.Color === value.Color && t.Title === value.Title)))
        uniqueCategories.forEach(category => {
          if (category.Color === defaultValue.Color) {
            if (category.Title !== defaultValue.Title) {
              uniqueCategories.push(defaultValue)
            }
          }
        })
        if (!uniqueCategories.find(o => o.Color === defaultValue.Color)) {
          uniqueCategories.push(defaultValue)
        }
        commit(UPDATED_CARD_CATEGORIES, uniqueCategories)
      }
    }
  },
  updateCategories({ commit }, categories) {
    commit(UPDATED_CARD_CATEGORIES, categories)
  },
  updateProjectSettingsData({commit}, projectSettings) {
    commit(UPLOAD_PROJECT_DATA_LOAD, projectSettings)
  },
  uploadCardsData({commit}, cards) {
    commit(UPLOAD_DATA_LOAD, cards)
  },
  initialLoadData({commit}) {
    commit(INITIAL_DATA_LOAD, Data)
  },
  updateCard({commit}, currentCard) {
    commit(CURRENT_CARD, currentCard)
  },
  swapCards({state}, cards) {
    if (cards.a !== null && cards.b !== null) {
      if (cards.a !== null && cards.b !== null) {
        const positionA = cards.a.PositionId
        const positionB = cards.b.PositionId
        cards.a.PositionId = positionB
        cards.b.PositionId = positionA
        state.cardsData[positionA] = cards.b
        state.cardsData[positionB] = cards.a
      }
    }
  },
  duplicateCards({state}, cards) {
    if (cards.a !== null && cards.b !== null) {
      let cardObjects = JSON.parse(JSON.stringify(cards))
      cardObjects.a.PositionId = cardObjects.b.PositionId
      state.cardsData[cards.b.PositionId] = cardObjects.a
    }
  }
}

const mutations = {
  [UPLOAD_PROJECT_DATA_LOAD](state, projectSettings) {
    if(!projectSettings.ProjectId) {
      projectSettings.ProjectId = 'proj-'+createGUID()
    }
    projectSettings.UserId = state.projectSettings.UserId
    state.projectSettings = projectSettings
  },
  [INITIAL_LOAD_ALL_CATEGORIES](state, allCategories) {
    state.allCategories = allCategories
  },
  [UPDATED_CARD_CATEGORIES](state, categories) {
    state.categories = categories
  },
  [INITIAL_DATA_LOAD](state, data) {
    const storedUserGUID = LS.getItem(LOCAL_STORAGE.UserGUID)
    state.cardsData = data.Cards
    if (process.env.NODE_ENV == 'development') {
      LS.setItem(LOCAL_STORAGE.UserGUID, process.env.VUE_APP_USER_GUID)
      data.ProjectSettings.UserId = process.env.VUE_APP_USER_GUID
    } else {
      if (storedUserGUID) {
        data.ProjectSettings.UserId = storedUserGUID
      } else {
        const userGUIDID = createGUID()
        LS.setItem(LOCAL_STORAGE.UserGUID, userGUIDID)
        data.ProjectSettings.UserId = userGUIDID
      }
    }
    data.ProjectSettings.ProjectId = 'proj-'+createGUID()
    state.projectSettings = data.ProjectSettings
  },
  [CURRENT_CARD](state, currentCard) {
    state.cardsData[currentCard.PositionId] = currentCard
  },
  [UPLOAD_DATA_LOAD](state, cards) {
    state.cardsData = cards
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}