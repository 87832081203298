<template>
  <div>
    <!-- Editor for existing points -->
    <PointEditor :pointIndex="0" :newPoint="true" />
    <hr class="modal-content-point-break">
    <v-row v-if="pointsQty > 0" class="modal-content-point-row">
      <v-col
        col="12"
        :sm="is1Point ? 12: 6"
        :md="is1Point ? 12: is2Points ? 6: 4"
        :lg="is1Point ? 12: is2Points ? 6: is3Points ? 4 : 3"
        :xl="is1Point ? 12: is2Points ? 6: is3Points ? 4 : is4Points ? 3 : 2"
        v-for="(point, i) in points" :key="i"
      >
        <PointEditor :pointContent="point" :pointIndex="i" :newPoint="false" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PointEditor from './add-content-point.vue'
export default {
  name: 'ContentPointsEditor',
  props: {
    contentPoints: {
      type: Array,
      default: () => []
    },
    editorActive: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    valid: true,
    newPointContent: {
      Title: '',
      Image: '',
      SubTitle: '',
      Description: '',
      Link: ''
    },
    points: []
  }),
  components: {
    PointEditor
  },
  computed: {
    getPoints() {
      return this.contentPoints
    },
    pointsQty() {
      if (this.points) {
        return this.points.length
      } else {
        return 0
      }
    },
    is1Point() {
      if (this.pointsQty === 1) {
        return true
      } else {
        return false
      }
    },
    is2Points() {
      if (this.pointsQty === 2) {
        return true
      } else {
        return false
      }
    },
    is3Points() {
      if (this.pointsQty === 3) {
        return true
      } else {
        return false
      }
    },
    is4Points() {
      if (this.pointsQty === 4) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    addNewPoint(newPoint) {
      this.points.push(newPoint)
    },
    removePoint(pointIndex) {
      this.points.splice(pointIndex, 1)
    },
    saveContent() {

    }
  },
  mounted() {
    this.points = this.getPoints
  }
}
</script>