<template>
  <v-btn class="header-button-active-toggle"
    @click="toggleActiveModeValue"
  >
    <span class="mr-2">Active Toggle Mode: <span v-if="activeToggleMode" class="button-active">ON</span><span v-if="!activeToggleMode" class="button-inactive">OFF</span></span>
    <v-icon v-if="!activeToggleMode" color="red">mdi-toggle-switch-off</v-icon>
    <v-icon v-if="activeToggleMode" color="green">mdi-toggle-switch</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'SwapButton',
  props: {
    activeToggleMode: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    toggleActiveModeValue() {
      this.$emit('active', this.activeToggleMode)
    }
  }
}
</script>