<template>
  <v-app class="main-app">
    <v-app-bar
      app
      dark
      class="header"
    >
      <div class="d-flex align-center">
        <h1 class="underline">{{getProjectSettings.ProjectName}}</h1>
        <h4 class="header-author" v-if="getProjectSettings.Author"> By: {{getProjectSettings.Author}}</h4>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs}">
            <v-icon 
              class="header-title-icon" 
              dark
              v-bind="attrs"
              v-on="on"
              @click="toggleProjectEditor"
            >
              mdi-cog
            </v-icon>
          </template>
          <span>Project settings</span>
        </v-tooltip>
      </div>

      <v-spacer></v-spacer>
      <!-- <TemplateOptions  class="header-button" /> -->
      <!-- <UploadData class="header-button header-button-upload" /> -->
      <!-- <DownloadData class="header-button header-button-download" /> -->
      <EditButton :editMode="editMode" @edited="toggleEditMode" class="header-button menu-edit-display" />
      <SwapButton :swapMode="swapMode" @swap="toggleSwapMode" class="header-button menu-swap-display" />
      <DuplicateButton :duplicateMode="duplicateMode" @duplicate="toggleDuplicateMode" class="header-button menu-duplicate-display" />
      <!-- <ClearButton :clearMode="clearMode" @clear="toggleClearMode" class="header-button" /> -->
      <ActiveToggleButton :activeToggleMode="activeToggleMode" @active="toggleActiveMode" class="header-button menu-active-toggle-display" />
      <v-btn @click="toggleNavDrawers" class="menu-display-button" ><v-icon>mdi-menu</v-icon></v-btn>
    </v-app-bar>

    <v-main class="main-section">
      <Table :editMode="editMode" :swapMode="swapMode" :duplicateMode="duplicateMode" :clearMode="clearMode" :activeToggleMode="activeToggleMode" />
    </v-main>
    <ProjectSettingsEditor :projectSettings="getProjectSettings" />

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      dark
      class="nav-drawer-container"
    >
      <div class="button-close-large-container">
        <v-btn @click="toggleNavDrawers" fab color="primary"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-list-item class="nav-drawer-title">
        <span class="nav-drawer-title-text">Periodic-8</span>
      </v-list-item>
      <v-divider class="nav-drawer-divider"></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <TemplateOptions />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <UploadData />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="nav-sidebar-button-spacing">
          <v-list-item-content>
            <DownloadData />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="nav-sidebar-button-spacing nav-edit-display">
          <v-list-item-content>
            <EditButton :editMode="editMode" @edited="toggleEditMode" class="nav-button-align" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="nav-sidebar-button-spacing nav-swap-display">
          <v-list-item-content>
            <SwapButton :swapMode="swapMode" @swap="toggleSwapMode" class="nav-button-align" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="nav-sidebar-button-spacing nav-duplicate-display">
          <v-list-item-content>
            <DuplicateButton :duplicateMode="duplicateMode" @duplicate="toggleDuplicateMode" class="nav-button-align" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="nav-sidebar-button-spacing">
          <v-list-item-content>
            <ClearButton :clearMode="clearMode" @clear="toggleClearMode" class="nav-button-align" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="nav-sidebar-button-spacing nav-active-toggle-display">
          <v-list-item-content>
            <ActiveToggleButton :activeToggleMode="activeToggleMode" @active="toggleActiveMode" class="nav-button-align" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="nav-footer">
        <span>Periodic-8 v-{{getAppVersion}}</span>
      </div>
    </v-navigation-drawer>

    <v-footer
      dark
      padless
      fixed
    >
      <v-card
        class="flex footer-card"
        flat
        tile
      >
        <v-card-text class="py-2 white--text text-center">
          <span class="footer-project-version">Periodic-8 v-{{getAppVersion}}</span>2020-{{new Date().getFullYear()}} &copy; - <strong>Created by: <a href="https://twitter.com/sqeel404" target="_blank" class="footer-creator">Sqeel404</a></strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Table from '@/components/table/table.vue'
import TemplateOptions from '@/components/buttons/template-options.vue'
import SwapButton from '@/components/buttons/swap-cards-button.vue'
import DuplicateButton from '@/components/buttons/duplicate-button.vue'
import EditButton from '@/components/buttons/edit-button.vue'
import DownloadData from '@/components/buttons/download-data.vue'
import ClearButton from '@/components/buttons/clear-button.vue'
import ActiveToggleButton from '@/components/buttons/active-toggle-button.vue'
import UploadData from '@/components/buttons/upload-data.vue'
import ProjectSettingsEditor from '@/components/modals/edit-project-settings.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Table,
    EditButton,
    DownloadData,
    UploadData,
    SwapButton,
    ClearButton,
    ActiveToggleButton,
    DuplicateButton,
    ProjectSettingsEditor,
    TemplateOptions
  },

  data: () => ({
    editMode: false,
    swapMode: false,
    duplicateMode: false,
    clearMode: false,
    activeToggleMode: false,
    drawer: false
  }),
  computed: {
    ...mapGetters([
      'getProjectSettings',
      'getAppVersion',
      'getProjectSettings'
    ])
  },
  methods: {
    ...mapActions([
      'switchProjectSettingsDisplayValue',
      'initialLoadData',
      'createInitialCategoriesArray',
      'setAppVersion'
    ]),
    toggleNavDrawers() {
      this.drawer = !this.drawer
    },
    toggleEditMode(editMode) {
      if (!editMode) {
        this.swapMode = false
        this.duplicateMode = false
        this.clearMode = false
        this.activeToggleMode = false
      }
      this.editMode = !editMode
    },
    toggleSwapMode(swapMode) {
      if (!swapMode) {
        this.editMode = false
        this.duplicateMode = false
        this.clearMode = false
        this.activeToggleMode = false
      }
      this.swapMode = !swapMode
    },
    toggleDuplicateMode(duplicateMode) {
      if(!duplicateMode) {
        this.editMode = false
        this.swapMode = false
        this.clearMode = false
        this.activeToggleMode = false
      }
      this.duplicateMode = !duplicateMode
    },
    toggleClearMode(clearMode) {
      if(!clearMode) {
        this.editMode = false
        this.swapMode = false
        this.duplicateMode = false
        this.activeToggleMode = false
      }
      this.clearMode = !clearMode
    },
    toggleActiveMode(activeToggleMode) {
      if(!activeToggleMode) {
        this.editMode = false
        this.swapMode = false
        this.duplicateMode = false
        this.clearMode = false
      }
      this.activeToggleMode = !activeToggleMode
    },
    toggleProjectEditor() {
      this.switchProjectSettingsDisplayValue()
    }
  },
  mounted() {
    this.initialLoadData()
    this.createInitialCategoriesArray()
    this.setAppVersion()
    if (process.env.NODE_ENV === 'production') {
      window.analytics.page()
      window.analytics.track('Initial Page Load', {
        projectId: this.getProjectSettings.ProjectId
      })
      if (this.getProjectSettings.UserId) {
        window.analytics.identify(this.getProjectSettings.UserId, {
          name: this.getProjectSettings.Author,
          projectId: this.getProjectSettings.ProjectId
        })
      } else {
        window.analytics.identify({
          name: this.getProjectSettings.Author,
          projectId: this.getProjectSettings.ProjectId
        })
      }
    }
  }
};
</script>