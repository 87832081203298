<template>
  <div class="info" style="z-index:10;">
    <v-card
      v-if="card.Active && !editMode && !swapMode && !duplicateMode"
      class="info-header"
      elevation="10"
    >
      <v-card-title class="info-title-container">
        <span class="info-symbol"
          ><span :style="`background-color:${card.Category.Color}`">{{
            card.Symbol
          }}</span>
          -</span
        >
        <span class="info-title">{{ card.Title }}</span>
      </v-card-title>
      <v-img class="info-image" :src="card.Icon"> </v-img>
      <span v-if="card.Code" class="info-header-code">{{ card.Code }}</span>
      <v-card-text class="info-description">
        <VueMarkdown :key="formattedContent">{{
          formattedContent
        }}</VueMarkdown>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { updateContentFormat } from "@/helpers/content-editor.js";
import VueMarkdown from "vue-markdown";
export default {
  name: "InfoHeader",
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: () => false,
    },
    swapMode: {
      type: Boolean,
      default: () => false,
    },
    duplicateMode: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    VueMarkdown,
  },
  data: () => ({}),
  computed: {
    formattedContent() {
      return updateContentFormat(this.card.Description);
    },
  },
};
</script>
