<template>
  <v-card class="modal-content-point-add-card" :style="`max-width: ${pointEditorSize}`">
    <v-btn @click="togglePointEditor" v-if="newPoint" class="modal-content-point-add-card-button-top"><span v-if="!editorOpen">Add Content Point Editor</span><span v-if="editorOpen">Close Point Editor</span></v-btn>
    <v-btn @click="togglePointEditor" v-if="!newPoint" class="modal-content-point-add-card-button-top"><span v-if="!editorOpen">Edit: {{pointContent.Title}}</span><span v-if="editorOpen">Close {{pointContent.Title}} Editor</span></v-btn>
    <v-card-text v-if="editorOpen">
      <v-form v-model="valid">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="modal-content-point-add-card-column"
          >
            <v-text-field
              label="Title"
              v-model="pointDetails.Title"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="modal-content-point-add-card-row">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="modal-content-point-add-card-column"
          >
            <v-text-field
              label="Image URL"
              v-model="pointDetails.Image"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="modal-content-point-add-card-row">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="modal-content-point-add-card-column"
          >
            <v-text-field
              label="Short Sub-title"
              v-model="pointDetails.SubTitle"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="modal-content-point-add-card-row">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="modal-content-point-add-card-column"
          >
            <v-textarea
              label="Description"
              v-model="pointDetails.Description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="modal-content-point-add-card-row">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="modal-content-point-add-card-column"
          >
            <v-text-field
              label="Link URL to Resource"
              v-model="pointDetails.Link"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-btn v-if="editorOpen && newPoint" @click="addNewPoint" class="modal-content-point-add-card-button-top">Add Content Point</v-btn>
      <v-btn v-if="editorOpen && !newPoint" @click="savePoint" class="modal-content-point-add-card-button-top">Save Content Point</v-btn>
      <v-btn v-if="editorOpen && !newPoint" @click="removePoint" class="modal-content-point-add-card-button-top">Remove Content Point</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PointEditor',
  props: {
    pointContent: {
      type: Object,
      default: () => ({
        Title: '',
        Image: '',
        SubTitle: '',
        Description: '',
        Link: ''
    })
    },
    pointIndex: {
      type: Number,
      default: () => null
    },
    newPoint: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    valid: true,
    editorOpen: false,
    pointDetails: {
      Title: '',
      Image: '',
      SubTitle: '',
      Description: '',
      Link: ''
    }
  }),
  computed: {
    pointEditorSize() {
      if (this.editorOpen) {
        return '30rem;'
      } else {
        return '15.7rem;'
      }
    }
  },
  methods: {
    togglePointEditor() {
      this.editorOpen = !this.editorOpen
    },
    removePoint() {
      this.$parent.removePoint(this.pointIndex)
      this.editorOpen = false
    },
    addNewPoint() {
      this.$parent.addNewPoint(this.pointDetails)
      this.editorOpen = false
    },
    savePoint() {
      this.editorOpen = false
    }
  },
  mounted() {
    this.pointDetails = this.pointContent
  }
}
</script>