<template>
  <v-btn @click="downloadData">
    <span class="mr-2">Download Data File</span>
    <v-icon>mdi-download</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
import download from "downloadjs";
export default {
  name: "DownloadDataButton",
  data: () => ({}),
  computed: {
    ...mapGetters(["getCardsData", "getProjectSettings"]),
    getDataRequest() {
      const request = {
        ProjectSettings: this.getProjectSettings,
        Cards: this.getCardsData,
      };
      return request;
    },
  },
  methods: {
    // downloadData() {
    //   alert("This feature is coming soon");
    // },
    downloadData() {
      download(
        JSON.stringify(this.getDataRequest),
        `${this.getProjectSettings.ProjectName}-periodic-8-data-${this.getProjectSettings.Version}.json`,
        "application/json"
      );
      if (process.env.NODE_ENV === "production") {
        window.analytics.track("Project Downloaded", {
          projectId: this.getProjectSettings.ProjectId,
          projectName: this.getProjectSettings.ProjectName,
          projectAuthor: this.getProjectSettings.Author,
          projectEmail: this.getProjectSettings.Email,
          projectVersion: this.getProjectSettings.Version,
        });
        if (this.getProjectSettings.UserId) {
          window.analytics.identify(this.getProjectSettings.UserId, {
            userId: this.getProjectSettings.Email,
            name: this.getProjectSettings.Author,
            projectId: this.getProjectSettings.ProjectId,
          });
        } else {
          window.analytics.identify({
            name: this.getProjectSettings.Author,
            projectId: this.getProjectSettings.ProjectId,
          });
        }
      }
    },
  },
};
</script>
