<template>
  <v-dialog
    v-model="contentDialog"
    persistent
    max-width="100%"
    max-height="100%"
    :key="contentDialog"
  >
    <v-card>
      <div class="button-close-large-container">
        <v-btn v-show="contentDialog" @click="closeContentDialog" fab color="primary" class="button-close-large"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-card-title class="info-title-container">
        <span class="info-symbol"><span :style="`background-color:${cardCategoryColor};`">{{cardData.Symbol}}</span> -</span> <span class="info-title">{{cardData.Title}}</span>
      </v-card-title>
      <v-img
        class="info-image"
        style="margin-top:2rem;"
        :src="cardData.Icon"
      >
      </v-img>
      <span v-if="card.Code" style="font-size:1vw; font-weight: 600;margin-left:2rem;">{{cardData.Code}}</span>
      <v-card-text  class="info-description">
        <VueMarkdown v-if="!editorActive" style="text-align: justify;">{{updateContentFormat(editorData)}}</VueMarkdown>
        <div v-if="editorActive" style="margin-top: 1rem;">
          <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
          <ContentPointsEditor :contentPoints="cardData.Points" :editorActive="editorActive"/>
        </div>
      </v-card-text>
      <hr v-if="!editorActive" style="margin: auto; max-width: 47%; margin-bottom:1rem;"/>
      <v-row v-if="pointsQty > 0" style="margin:auto;">
        <v-col
          col="12"
          :sm="is1Point ? 12: 6"
          :md="is1Point ? 12: is2Points ? 6: 4"
          :lg="is1Point ? 12: is2Points ? 6: is3Points ? 4 : 3"
          :xl="is1Point ? 12: is2Points ? 6: is3Points ? 4 : is4Points ? 3 : 2"
          v-for="(card, i) in card.Points" :key="i"
        >
          <InfoCard v-if="!editorActive" :cardContent="card" />
        </v-col>
      </v-row>
      <v-card-actions style="bottom:0; margin-top:2rem;">
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="toggleEditor"
          style="float: left;"
        >
          <span v-if="!editorActive">Open Editor</span><span v-if="editorActive">Close Editor</span>
        </v-btn>
        <v-btn
          :disabled="editorActive"
          color="blue darken-1"
          text
          @click="saveContentDialog"
          
        >
          Save
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="closeContentDialog"
          
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import { updateContentFormat } from '@/helpers/content-editor.js'
import { mapActions } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// vue-markdown: https://github.com/miaolz123/vue-markdown
import VueMarkdown from 'vue-markdown'
import InfoCard from '@/components/cards/info-card.vue'
import ContentPointsEditor from '@/components/modals/content-points/content-points-editor.vue'
export default {
  name: 'ContentModal',
  props: {
    contentDialog: {
      type: Boolean,
      default: () => false
    },
    card: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    cardData: {},
    editorActive: false,
    editor: ClassicEditor,
    editorData: '',
    // toolbar details: https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html
    // ckeditor plugins: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/installing-plugins.html
    editorConfig: {
      toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'blockQuote', '|', 'redo', 'undo' ]
    },
    updateContentFormat
  }),
  components: {
    VueMarkdown,
    InfoCard,
    ContentPointsEditor
  },
  computed: {
    cardCategoryColor() {
      if (this.cardData.Category?.Color) {
        return this.cardData.Category.Color
      } else {
        return '#d3d3d3ff'
      }
    },
    pointsQty() {
      if (this.card?.Points) {
        return this.card.Points.length
      } else {
        return 0
      }
    },
    is1Point() {
      if (this.pointsQty === 1) {
        return true
      } else {
        return false
      }
    },
    is2Points() {
      if (this.pointsQty === 2) {
        return true
      } else {
        return false
      }
    },
    is3Points() {
      if (this.pointsQty === 3) {
        return true
      } else {
        return false
      }
    },
    is4Points() {
      if (this.pointsQty === 4) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions([
      'updateCard'
    ]),
    closeContentDialog() {
      this.$parent.closeContentDialog()
      this.editorData = ''
      this.cardData = {}
      this.editorActive = false
    },
    toggleEditor() {
      this.editorActive = !this.editorActive
    },
    saveContentDialog() {
      this.cardData.Content = this.editorData
      this.updateCard(this.cardData)
      this.closeContentDialog()
    }
  },
  watch: {
    contentDialog(newValue) {
      if (newValue) {
        this.cardData = this.card
        this.editorData = this.card.Content
      } 
    }
  }
}
</script>