<template>
  <v-btn class="header-button-swap"
    @click="toggleSwapModeValue"
  >
    <span class="mr-2">Swap Mode: <span v-if="swapMode" class="button-active">ON</span><span v-if="!swapMode" class="button-inactive">OFF</span></span>
    <v-icon :color="swapMode ? 'green': 'red'">mdi-swap-vertical-bold</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'SwapButton',
  props: {
    swapMode: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    toggleSwapModeValue() {
      this.$emit('swap', this.swapMode)
    }
  }
}
</script>