import Vue from 'vue'
import Vuex from 'vuex'

import cards from './cards'
import project from './project'
import application from './application'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cards,
    project,
    application
  }
})