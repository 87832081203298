import Vue from "vue";
import App from "./App.vue";

import store from "./store";

// Plugins
import vMediaQuery from "v-media-query";
import vuetify from "./plugins/vuetify";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueSegmentAnalytics from "vue-segment-analytics";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Helper utils
import "@/utils/simpleCrypt.js";

// Assets
import "@/assets/sass/style.scss";

import "./registerServiceWorker";

Vue.config.productionTip = false;

// Use v-media-query
Vue.use(vMediaQuery);

// use the WYSIWYG editor for vue
// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/vuejs-v2.html
Vue.use(CKEditor);

// if in production run the below
if (process.env.NODE_ENV !== "development") {
  // Segment Analytics
  // segment usage and configuration: https://github.com/segmentio/analytics-vue
  Vue.use(VueSegmentAnalytics, {
    id: process.env.VUE_APP_SEGMENT_KEY,
  });
  // Sentry error monitoring
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
