// Simple encryption/decryption

/**
 * Encryption example:
 * const secret = btoa(JSON.stringify(object).normToAscii().cryptSym())
 * 
 * Decryption example:
 * const reveal = JSON.parse(atob(secret).cryptSym().normToUnicode())
 * 
 */

String.prototype.normToAscii = function () { return unescape(encodeURIComponent(this)) }
String.prototype.normToUnicode = function () { return decodeURIComponent(escape(this)) }
String.prototype.cryptSym = function (k) {
  return String.fromCharCode.apply(
    undefined,
    this.split('').map(function (c) {
      return c.charCodeAt(0) ^ (k || 13)
    })
  )
}