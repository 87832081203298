import {
  PROJECT_SETTINGS_DISPLAY_UPDATE
} from '@/store/mutation-types'

const state = {
  projectSettingsDisplayed: false
}

const getters = {
  getProjectSettingsDisplayValue() { return state.projectSettingsDisplayed }
}

const actions = {
  switchProjectSettingsDisplayValue({commit, state}) {
    commit(PROJECT_SETTINGS_DISPLAY_UPDATE, !state.projectSettingsDisplayed)
  }
}

const mutations = {
  [PROJECT_SETTINGS_DISPLAY_UPDATE](state, value) {
    state.projectSettingsDisplayed = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}