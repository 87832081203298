<template>
  <v-btn class="header-button-clear"
    @click="toggleClearModeValue"
  >
    <span class="mr-2">Clear Mode: <span v-if="clearMode" class="button-active">ON</span><span v-if="!clearMode" class="button-inactive">OFF</span></span>
    <v-icon :color="clearMode ? 'green': 'red'">mdi-restart</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'SwapButton',
  props: {
    clearMode: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    toggleClearModeValue() {
      this.$emit('clear', this.clearMode)
    }
  }
}
</script>