import { mapValues, some, values } from 'lodash'

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const getValues = (object) => mapValues(object, value => {
  const objectOfObject = some(value, prop => (typeof prop === 'object'))
  return objectOfObject ? getValues(value) : values(value)
})

export const scrollTo = (left, top, behaviour) => {
  window.scrollTo({ top, left, behaviour })
}

export const convertToArrayOptions = (options) => {
  return Object.keys(options).map((d) => {
    return {
      id: d,
      value: options[d]
    }
  })
}

// Local Storage methods
export const LS = {
  getItem (label) {
    return window.localStorage.getItem(label)
  },
  setItem (label, value) {
    return window.localStorage.setItem(label, value)
  },
  removeItem (label) {
    window.localStorage.removeItem(label)
  }
}

// Session storage methods
export const SS = {
  getItem (label) {
    return window.sessionStorage.getItem(label)
  },
  setItem (label, value) {
    return window.sessionStorage.setItem(label, value)
  },
  removeItem (label) {
    window.sessionStorage.removeItem(label)
  }
}

// Check if screen size is desktop
export const isDesktopScreen = (mq) => {
  if (mq === 'lg') {
    return true
  } else {
    return false
  }
}

// Check if the screen is small mobile device
export const isSmallScreenDevice = (mq) => {
  if (mq === 'sm') {
    return true
  } else {
    return false
  }
}

export const returnedSelectionFromList = (list, value) => {
  return list[value] || list[1]
}

// Filter Categories to only return values if there are articles
export const removeCategoriesWithNoArticles = (categories) => {
  let filteredCategoryList = []
  categories.forEach(category => {
    if (category.articles.length > 0) {
      filteredCategoryList.push(category)
    }
  })
  return filteredCategoryList
}

// Dynamic sorting method for Objects
// https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
export const dynamicSort = (property) => {
  var sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a,b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property])
    } else {
      return a[property].localeCompare(b[property])
    }
  }
}

export const waitInMilliseconds = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

// create a GUID: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export const createGUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// Media query methods
// check if desktop based on screen size and return true or false based on $mq
export const isDesktop = (mq) => {
  if (mq.resize && mq.above(769)) {
    return true
  } else {
    return false
  }
}