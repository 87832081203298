<template>
  <div style="margin: 1rem 1rem 0 0;">
    <v-select
      :items="selectOptions"
      v-model="selectedTemplate"
      item-text="id"
      item-value="id"
      return-object
      label="Select Starting Template"
      style="display:inline-block; margin-right: 1rem; max-width: 10rem;"
    ></v-select>
    <v-btn @click="loadSelectedTemplate" style="display:inline-block;">Load
      <v-icon style="margin-left: 5px;">mdi-reload</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { convertToArrayOptions, createGUID } from '@/helpers'
import { defaultTemplates } from '@/data/templates/template-details.js'

//templates
import Default from '@/data/card-data/card-data.json'
import DefaultEmpty from '@/data/card-data/card-data-empty.json'
import CodeLanguages from '@/data/card-data/card-data-coding.json'
import Elements from '@/data/card-data/card-data-elements.json'
import EightBit from '@/data/card-data/card-data-8bit.json'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TemplateDropdownSelect',
  data: () => ({
    selectedTemplate: {}
  }),
  computed: {
    ...mapGetters([
      'getProjectSettings'
    ]),
    selectOptions() {
      return convertToArrayOptions(defaultTemplates)
    }
  },
  methods: {
    ...mapActions([
      'uploadCardsData',
      'updateProjectSettingsData',
      'createInitialCategoriesArray'
    ]),
    loadSelectedTemplate() {
      let results = {}
      switch(this.selectedTemplate.value) {
        case 'default':
          results = Default
          break
        case 'empty':
          results = DefaultEmpty
          break
        case 'coding':
          results = CodeLanguages
          break
        case 'elements':
          results = Elements
          break
        case '8bit':
          results = EightBit
          break
        default:
          break
      }
      if (Object.entries(results).length > 0) {
        results.ProjectSettings.ProjectId = createGUID()
        if (this.getProjectSettings.Author.length > 0 && results.ProjectSettings.Author.length == 0) {
          results.ProjectSettings.Author = this.getProjectSettings.Author
        }
        if (this.getProjectSettings.Email.length > 0 && results.ProjectSettings.Email.length == 0) {
          results.ProjectSettings.Email = this.getProjectSettings.Email
        }
        this.updateProjectSettingsData(results.ProjectSettings)
        this.uploadCardsData(results.Cards)
        this.createInitialCategoriesArray()
      }
    }
  }
}
</script>