<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="40rem"
      :key="dialog"
    >
      <v-card>
        <div class="button-close-large-container">
          <v-btn v-show="dialog" @click="closeDialog" fab color="primary" class="button-close-large"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-card-title>
          <span class="headline"><span class="editor-heading-bold underline">Editing: <br>Title: </span>{{currentCard.Title}}<span class="editor-heading-bold"> - Block:</span> {{currentCard.PositionId +1}}<span class="editor-heading-bold"> - Symbol:</span> {{currentCard.Symbol}}:</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select
                  :items="[true, false]"
                  v-model="currentActiveValue"
                  label="Is card active?"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="3"
                md="2"
                v-if="currentActiveValue"
              >
                <v-text-field
                  label="ID#"
                  :rules="rulesSymbol"
                  v-model="dialogData.displayId"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="currentActiveValue"
              >
                <v-text-field
                  label="Code"
                  :rules="rulesCode"
                  v-model="dialogData.code"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="currentActiveValue"
              >
                <v-text-field
                  label="Symbol"
                  :rules="rulesSymbol"
                  v-model="dialogData.symbol"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="currentActiveValue"
              >
                <v-text-field
                  label="Title"
                  v-model="dialogData.title"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="currentActiveValue">
              <v-col
                col="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Icon Url"
                  v-model="dialogData.icon"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="currentActiveValue">
              <v-col
                col="12"
                sm="12"
                md="12"
              >
                <ckeditor :editor="editor" v-model="dialogData.description" :config="editorConfig"></ckeditor>
                <v-btn @click="toggleCodeEditor">View Source</v-btn>
                <v-textarea
                  v-if="codeEditor"
                  label="Description Source Code:"
                  v-model="dialogData.description"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
              <!-- exmaple for v-select with objects: https://stackoverflow.com/questions/51296834/vuejs-vuetify-how-to-access-properties-of-object-in-v-select -->
                <v-select
                  :items="getCategories"
                  v-model="selectedCategory"
                  item-text="Title"
                  item-value="Title"
                  return-object
                  label="Select a Category"
                  @change="changeCategory"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-btn  v-if="currentActiveValue" @click="editCategoryDetails">Add/Edit Category:</v-btn>
            <v-row v-if="currentActiveValue && editCategory">
              <v-col
                col="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Category Title"
                  v-model="dialogData.category"
                ></v-text-field>
              </v-col>
              <v-col
                col="12"
                sm="6"
                md="6"
              >
                <v-color-picker
                  style="margin:auto"
                  v-model="dialogData.color"
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="blue darken-1"
            text
            @click="saveDialog"
          >
            Save
        </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Close
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'EditModal',
  props: {
    card: {
      type: Object,
      default: () => {}
    },
    dialog: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    valid: true,
    currentActiveValue: true,
    selectedCategory: {},
    editCategory: false,
    currentCard: {},
    newCategory: {
      Title: '',
      Color: ''
    },
    dialogData: {
      active: true,
      displayId: '',
      title: '',
      color: '#d3d3d3ff',
      category: 'None',
      symbol: '',
      description: '',
      icon: '',
      code: ''
    },
    validationRules: {
      maxCharactersSymbol: 3,
      maxCharactersCode: 8
    },
    editor: ClassicEditor,
    editorConfig: {
      toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'redo', 'undo' ]
    },
    codeEditor: false
  }),
  computed: {
    ...mapGetters([
      'getCategories',
      'getProjectSettings'
    ]),
    rulesSymbol() {
      const rules = []
      if (this.validationRules.maxCharactersSymbol) {
        const rule =
          v => (v || '').length <= this.validationRules.maxCharactersSymbol || `Symbol has max of ${this.validationRules.maxCharactersSymbol} characters`
          rules.push(rule)
      }
      return rules
    },
    symbolLength() {
      return this.getProjectSettings.SymbolLength
    },
    rulesCode() {
      const rules = []
      if (this.validationRules.maxCharactersCode) {
        const rule =
          v => (v || '').length <= this.validationRules.maxCharactersCode || `Symbol has max of ${this.validationRules.maxCharactersCode} characters`
          rules.push(rule)
      }
      return rules
    },
    codeLength() {
      return this.getProjectSettings.CodeLength
    }
  },
  methods: {
    ...mapActions([
      'updateCard',
      'createInitialCategoriesArray'
    ]),
    toggleCodeEditor() {
      this.codeEditor = !this.codeEditor
    },
    editCategoryDetails() {
      this.editCategory = !this.editCategory
    },
    changeCategory(newCategory) {
      this.dialogData.color = newCategory.Color
      this.dialogData.category = newCategory.Title
    },
    closeDialog() {
      this.resetData()
      this.$parent.closeDialog()
    },
    resetData() {
      this.currentActiveValue = true
      this.dialogData.active = true
      this.dialogData.displayId = ''
      this.dialogData.title = ''
      this.dialogData.color = ''
      this.dialogData.category = ''
      this.dialogData.symbol = ''
      this.dialogData.description = ''
      this.dialogData.icon = ''
      this.dialogData.code = ''
      this.editCategory = false
      this.selectedCategory = {}
      this.newCategory.Title = this.newCategory.Color = ''
    },
    saveDialog() {
      if (this.dialogData.title)
        this.currentCard.Title = this.dialogData.title

      if (this.dialogData.color && this.dialogData.category){
        this.currentCard.Category.Color = this.dialogData.color
        this.currentCard.Category.Title = this.dialogData.category
      }
      
      if (this.dialogData.symbol)
        this.currentCard.Symbol = this.dialogData.symbol

      this.currentCard.Code = this.dialogData.code
      this.currentCard.DisplayId = this.dialogData.displayId
      this.currentCard.Icon = this.dialogData.icon
      this.currentCard.Description = this.dialogData.description
      this.currentCard.Active = this.dialogData.active
      this.createInitialCategoriesArray()
      this.updateCard(this.currentCard)
      this.closeDialog()
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.currentCard = this.card
        this.dialogData.active = this.currentActiveValue = this.card.Active
        this.dialogData.title = this.card.Title
        this.dialogData.color = this.card.Category.Color
        this.dialogData.category = this.card.Category.Title
        this.selectedCategory = this.card.Category
        this.dialogData.symbol = this.card.Symbol
        this.dialogData.description = this.card.Description
        this.dialogData.icon = this.card.Icon
        this.dialogData.displayId = this.card.DisplayId
        this.dialogData.code = this.card.Code
      } else {
        this.resetData()
      }
    },
    currentActiveValue(newValue) {
      this.dialogData.active = newValue
    },
    symbolLength(newValue) {
      this.validationRules.maxCharactersSymbol = newValue
    },
    codeLength(newValue) {
      this.validationRules.maxCharactersCode = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-heading-bold {
  font-weight: 700;
  &.underline {
    text-decoration: underline;
  }
}
</style>