<template>
  <div class="info info-categories">
    <div class="info-header">
      <div>
        <h2>Categories:</h2>
        <!-- <v-btn @click="editCategoriesModal" class="edit-categories-button">
          <span>Edit Categories</span>
        </v-btn> -->
      </div>
      <ul class="info-categories-list" :style="`column-count: ${getListColumnCount}`">
        <Draggable v-if="isDesktopScreen" v-model="myCategoryList" group="category" @start="drag=true" @end="drag=false">
          <div v-for="(category, i) in getCategories" :key="i">
            <li v-if="category.Title !== 'None'" class="info-categories-list-category" style="cursor: grab;">
              <div :style="`background-color:${category.Color} !important;`" class="info-categories-list-color-block"></div>
              <span class="info-categories-category-title"> {{category.Title}}</span>
            </li>
          </div>
        </Draggable>
        <div v-else v-for="(category, i) in getCategories" :key="i">
          <li v-if="category.Title !== 'None'" class="info-categories-list-category">
            <div :style="`background-color:${category.Color} !important;`" class="info-categories-list-color-block"></div>
            <span class="info-categories-category-title"> {{category.Title}}</span>
          </li>
        </div>
      </ul>
    </div>
    <!-- <EditCategories :dialog="dialog" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dynamicSort, isDesktop } from '@/helpers'
import Draggable from 'vuedraggable'
// import EditCategories from '@/components/modals/edit-categories.vue'
export default {
  name: "CategoryPanel",
  data: () => ({
    dynamicSort,
    dialog: false
  }),
  computed: {
    ...mapGetters([
      'getCategories'
    ]),
    myCategoryList: {
      get() {
        return this.getCategories
      },
      set(updatedCategoriesList) {
        this.updateCategories(updatedCategoriesList)
      }
    },
    isDesktopScreen() {
      return isDesktop(this.$mq)
    },
    getListColumnCount() {
      const categoryCount = this.getCategories.length
      if (categoryCount <= 8) {
        return 1
      } else if (categoryCount > 8 && categoryCount <= 16) {
        return 2
      } else if (categoryCount > 16 && categoryCount <= 24) {
        return 3
      } else {
        return 4
      }
    }
  },
  components: {
    // EditCategories
    Draggable
  },
  methods: {
    ...mapActions([
      'updateCategories'
    ]),
    editCategoriesModal() {
      this.dialog = !this.dialog
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-categories-button {
  float: right;
  display: inline-block;
}
</style>